<template>
  <ul class="pager" v-if="props.total > 0">
    <li
      class="prev"
      :class="{ disable: props.now <= 1 }"
      :data-page="props.now > 1 ? props.now - 1 : 1"
      @click="onPage"
    ></li>
    <li v-if="props.now > state.half" class="more"></li>
    <li
      v-for="i in props.show > props.total ? props.total : props.show"
      :key="i"
      :class="{ act: state.start + i === props.now }"
      :data-page="state.start + i"
      @click="onPage"
    >
      {{ state.start + i }}
    </li>
    <li v-if="props.start + props.show < props.total" class="more"></li>
    <li
      class="next"
      :class="{ disable: props.now >= props.total }"
      :data-page="props.now >= props.total ? props.total : props.now + 1"
      @click="onPage"
    ></li>
    <li class="total">
      共<b>{{ props.total }}</b
      >页
    </li>
    <li class="goto">到第<input type="text" v-model="state.go" />页</li>
    <li class="go" @click="gotoPage">确定</li>
  </ul>
</template>

<script>
import { reactive, computed, onMounted } from "vue";

export default {
  props: ["now", "total", "show"],

  setup(props, { emit }) {
    const state = reactive({
      half: 0,
      start: computed(() => {
        const half = state.half;
        const total = props.total;
        const now = props.now;
        let start = 0;

        if (now < half) start = 0;
        else if (now > total - half)
          start = total > props.show ? total - props.show : 0;
        else start = now - half;
        // console.log(start, now, total);

        return start;
      }),
      go: "",
    });

    onMounted(() => {
      const half = Math.floor(props.show / 2);
      state.half = half;
    });

    const onPage = (e) => {
      const page = e.currentTarget.dataset.page;
      if (!page) return;

      emit("onPage", {
        page: page,
      });
    };

    const gotoPage = () => {
      if (!state.go || isNaN(state.go)) return;
      const page = parseInt(state.go, 10);

      emit("onPage", {
        page: page,
      });
    };

    return { state, props, onPage, gotoPage };
  },
};
</script>

<style lang="less">
.pager {
  margin-top: 60px;
  font-size: 0;
  text-align: right;

  li {
    min-width: 28px;
    height: 28px;
    padding: 0 3px;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    line-height: 26px;
    background: #ffffff;
    color: #414141;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    margin-right: 8px;
    cursor: pointer;
  }

  li:hover {
    background-color: #dfdfdf;
  }

  li.act,
  li.act:hover {
    border: #0273ff solid 1px;
    background: #0273ff;
    color: #fff;
  }

  .more,
  .total,
  .goto {
    padding: 0;
    border: transparent 1px solid;
    background: transparent;
    color: #444;

    &:hover {
      background: transparent;
      cursor: default;
    }
  }

  .more {
    &::before {
      content: "…";
    }
  }

  .goto input {
    width: 28px;
    padding: 0 3px;
    height: 26px;
    line-height: 24px;
    box-sizing: border-box;
    border: 1px solid #d9d9d9;
    outline: none;
    text-align: center;
    font-size: 12px;
    margin: 0 2px;
    border-radius: 2px;
  }

  .next,
  .prev {
    background: #fff
      url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHBhdGggZD0iTTU5My40NSA1MTIuMTI4TDM2MC4wNjUgMjc4LjYxM2w0NS4yOS00NS4yMjYgMjc4LjYxNCAyNzguNzYyLTI3OC42MzUgMjc4LjQ2NC00NS4yMjYtNDUuMjY5eiIgZmlsbD0iIzk5OSIvPjwvc3ZnPg==")
      no-repeat center;
    background-size: 14px;
  }

  .prev {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCI+PHBhdGggZD0iTTY0MS4yOCAyNzguNjEzbC00NS4yMjctNDUuMjI2TDMxNy40MiA1MTIuMTQ5bDI3OC42MTMgMjc4LjQ4NiA0NS4yNDgtNDUuMjctMjMzLjM2NS0yMzMuMjM3eiIgZmlsbD0iIzk5OSIvPjwvc3ZnPg==");
  }

  .disable,
  .disable:hover {
    background-color: #f2f2f2;
    cursor: not-allowed;
  }
}
</style>
