<template>
  <div id="search">
    <tmpl_header />

    <div class="containter">
      <!--div class="breadcrumb">
        <ul>
          <li class="r"><a href="#">手机数码</a></li>
          <li class="r"><a href="#">智能手机</a></li>
          <li class="r">
            <div class="picker"><span>智能手机</span></div>
          </li>
          <li>
            <div class="params">网络：<b>5G</b><a href="#"></a></div>
          </li>
          <li><a href="#" class="clear">清空筛选</a></li>
        </ul>
      </div-->

      <div class="filters" v-if="brands">
        <ul>
          <li>
            <strong>品牌</strong>
            <div>
              <a v-for="item in brands" :key="item.brandId">{{
                item.brandName
              }}</a>
            </div>
          </li>
          <!--li>
            <strong>内存</strong>
            <div>
              <a href="#">128G</a>
              <a href="#">256G</a>
              <a href="#">512G</a>
            </div>
          </li>
          <li>
            <strong>其他选项</strong>
            <div>
              <a class="picker" href="#">网络制式</a>
              <a class="picker" href="#">屏幕尺寸</a>
            </div>
          </li-->
        </ul>
      </div>

      <div class="operates">
        <ul class="sort">
          <li :class="{ act: sort == '' }" @click="onSort('')">默认</li>
          <li class="down" :class="{ act: sort == '2D' }" @click="onSort('2D')">
            销量
          </li>
          <li class="down" :class="{ act: sort == '3D' }" @click="onSort('3D')">
            上架时间
          </li>
          <li
            class="switch"
            :class="{
              act: sort == '1D' || sort == '11D',
              a: sort == '11D',
              v: sort == '1D',
            }"
            @click="onSort(sort == '11D' ? '1D' : '11D')"
          >
            价格
          </li>
          <li class="price">
            <label
              ><input name="min" placeholder="¥" v-model="priceMin"
            /></label>
            <i>-</i>
            <label
              ><input name="max" placeholder="¥" v-model="priceMax"
            /></label>
            <button
              @click="changePrice"
              v-if="
                (priceMin != pMin || priceMax != pMax) && priceMin && priceMax
              "
            >
              确认
            </button>
            <button @click="onClearPrice" v-if="priceMin || priceMax">
              清除
            </button>
          </li>
        </ul>
        <div class="page">
          <b>共{{ totalRows }}件商品</b>
          <a
            class="prev"
            @click="onPage({ page: pageNow > 1 ? pageNow - 1 : 1 })"
          ></a>
          <span>{{ pageTotal > 0 ? pageNow : 0 }}/{{ pageTotal }}</span>
          <a
            class="next"
            @click="
              onPage({ page: pageNow < pageTotal ? pageNow + 1 : pageTotal })
            "
          ></a>
        </div>
      </div>

      <div v-if="!loading && (!products || !products.length)" class="empty">
        <h3 v-if="keyword">
          <img src="@/assets/img/icon_warning.svg" />
          抱歉，没有找到与“<b>{{ keyword }}</b
          >”相关的商品
        </h3>
        <h3 v-if="!keyword">
          <img src="@/assets/img/icon_warning.svg" />
          抱歉，没有找到相关的商品
        </h3>
        <p>
          建议您：<br />1、适当减少筛选条件<br />2、调整价格区间<br />3、尝试其他关键词
        </p>
      </div>

      <div
        v-if="products && products.length > 0"
        class="products"
        :class="{ loading: loading }"
      >
        <ul class="clear">
          <li v-for="(pro, i) in products" :key="pro.goodsInfoId">
            <router-link class="pro" :to="`/goods/view/${pro.goodsInfoId}`">
              <img :src="pro.imgList[0].imageThumName" />
              <p>{{ pro.goodsInfoPreferPrice }}</p>
              <strong v-html="pro.goodsInfoName"></strong>
              <label class="tags">
                <i>自营</i>
              </label>
              <button
                class="favor"
                type="button"
                :class="{ favored: pro.follow }"
                :data-i="i"
                @click.stop.prevent="onFavor"
              >
                {{ pro.follow ? "已收藏" : "收藏" }}
              </button>
              <button
                class="buy"
                type="button"
                :data-id="pro.goodsInfoId"
                @click.stop.prevent="onBuy"
              >
                加入购物车
              </button>
            </router-link>
          </li>
        </ul>
      </div>

      <pager
        v-if="products && products.length > 0"
        :now="pageNow"
        :show="pageShow"
        :total="pageTotal"
        @onPage="onPage"
      ></pager>
    </div>

    <tmpl_footer />

    <div class="fixbtns">
      <a class="favor" @click="favorSite">收藏</a>
      <a class="service" href="javascript:;"
        >在线客服<img src="../../assets/img/serv_qrcode.png"
      /></a>
      <a class="top" href="#"></a>
    </div>
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import pager from "../../components/common/pager.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
      keyword: "",
      catIds: "",
      brands: null,
      sort: "",
      priceMax: "",
      priceMin: "",
      pMax: "",
      pMin: "",

      pageRows: 20,
      pageNow: 1,
      pageShow: 10,
      pageTotal: 1,
      products: [],
      totalRows: 0,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
    pager,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  watch: {
    $route(to, from) {
      if (
        to.path === "/search" &&
        (to.query.keyword !== from.query.keyword ||
          to.query.cid !== from.query.cid)
      ) {
        this.loadSearch();
      }
    },
  },

  created: function () {
    // console.log(this.$route);

    api.all([this.loadSearch()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadSearch() {
      this.keyword = this.$route.query.keyword || "";
      this.catIds = this.$route.query.cid || "";

      if (this.loading) return;
      this.loading = true;

      const params = {
        pageNo: this.pageNow,
        rows: this.pageRows,
        sort: this.sort,
        //brandName: this.brandName,
      };
      if (this.catIds) {
        params.cats = this.catIds.split(",").map((i) => {
          return parseInt(i);
        });
      }
      if (this.keyword != "") {
        params.keyWords = this.keyword
          .replace(
            /[`～*~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
            ""
          )
          .replace(/\s/g, "");
      }
      if (this.pMax) {
        params.priceMin = this.pMin;
        params.priceMax = this.pMax;
      }

      return api.post(apis.search.products, params).then((res) => {
        this.totalRows = res.dataNum;
        this.brands = res.brands;
        this.params = res.params;
        this.pageNow = res.pb.pageNo;
        this.pageTotal = res.pb.totalPages;
        this.products = res.pb.data;
        this.loading = false;
      });
    },

    changePrice() {
      const pMax = parseInt(this.priceMax, 10);
      const pMin = parseInt(this.priceMin, 10);
      if (isNaN(pMax) || isNaN(pMin)) return;
      this.pMax = pMax;
      this.pMin = pMin;
      this.pageNow = 1;
      this.loadSearch();
    },

    onClearPrice() {
      this.priceMin = "";
      this.priceMax = "";
      this.pMax = "";
      this.pMin = "";
      this.pageNow = 1;
      this.loadSearch();
    },

    onPage(e) {
      this.pageNow = e.page;
      this.loadSearch();
    },

    onSort(sort) {
      this.pageNow = 1;
      this.sort = sort;
      this.loadSearch();
    },

    onFavor(e) {
      if (!this.username) {
        return store.dispatch("alert", {
          icon: "warning",
          msg: "您还没有登录账号",
        });
      }

      var i = e.currentTarget.dataset.i,
        pro = this.products[i],
        goodsId = pro.goodsInfoId,
        followId = pro.followId,
        price = pro.goodsInfoPreferPrice;

      if (this.products[i].follow) {
        api
          .post(apis.center.removeFavor, {
            followIds: [followId],
          })
          .then((res) => {
            console.log(res);
            this.products[i].follow = false;
            store.dispatch("toast", "已取消收藏");
          });
      } else {
        const params = {
          follows: [
            {
              goodsId: goodsId,
              followPrice: price,
            },
          ],
        };
        api.post(apis.center.addFavor, params).then((res) => {
          console.log(res);
          this.products[i].follow = true;
          store.dispatch("toast", "已加入收藏");
        });
      }
    },

    onBuy(e) {
      if (!this.username) {
        return store.dispatch("alert", {
          icon: "warning",
          msg: "您还没有登录账号",
        });
      }

      const productId = e.currentTarget.dataset.id;

      return api
        .post(apis.cart.add, {
          productId: productId,
          goodsNum: 1,
          distinctId: 1,
        })
        .then((res) => {
          console.log(res);
          store.dispatch("needUpdateCart");
          store.dispatch("toast", "已加入购物车");
        });
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./index.less");
</style>
